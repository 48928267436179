import _ from 'lodash'
import {
  formatCategoryName,
  generateCounterOptionKey,
  generateId,
  getCurrentNbOption,
  getNbInCartOfProduct,
  getOptionAssociatedProduct,
  getProductUnits,
  getStockRemaining,
  sendCloudWatchLogs,
} from '../../../utils'
import {
  CATEGORY_SEPARATOR,
  ITEM_KEY_CATEGORY_SEPARATOR,
  ITEM_KEY_COUNTER_SEPARATOR,
  NOT_FOUND_INDEX
} from '../../../constants'
import { removeUndefinedProperties } from '../../../utils/FunctionUtils'

const getCategoryFromItemRecursiveKey = itemRecursiveKey => {
  if (!itemRecursiveKey?.includes(ITEM_KEY_CATEGORY_SEPARATOR)) {
    return null;
  }
  const lastSeparatorIndex = itemRecursiveKey.lastIndexOf(ITEM_KEY_CATEGORY_SEPARATOR);
  return itemRecursiveKey.substring(0, lastSeparatorIndex);
}

const getItemOriginalKeyFromSubItemRecursiveKey = itemRecursiveKey => {
  if (!itemRecursiveKey?.includes(ITEM_KEY_CATEGORY_SEPARATOR)) {
    return itemRecursiveKey;
  }
  const splitItemFullKey = itemRecursiveKey.split(ITEM_KEY_CATEGORY_SEPARATOR)
  return splitItemFullKey?.[splitItemFullKey?.length - 2]?.split(ITEM_KEY_COUNTER_SEPARATOR)?.[0];
}

export const generateSubcategoryKey = (parentItemRecursiveKey, subcategoryKey) => `${parentItemRecursiveKey}${ITEM_KEY_CATEGORY_SEPARATOR}${subcategoryKey}`

export const isUniqueOption = option => option?.min === 1 && option?.max === 1;

export const generateItemRecursiveKey = (item, counter) => {
  const itemInitialKey = `${item.categoryKey}${ITEM_KEY_CATEGORY_SEPARATOR}${item.key}`;
  return counter !== undefined && parseInt(counter, 10) >= 0
    ? `${itemInitialKey}${ITEM_KEY_COUNTER_SEPARATOR}${counter}`
    : itemInitialKey;
}

const generateCategoriesToRemove = (removedItem, itemRecursiveKey) =>
  !removedItem?.subcategories?.length
    ? []
    : removedItem.subcategories.split(CATEGORY_SEPARATOR)
      .map(subCategory => generateSubcategoryKey(itemRecursiveKey || removedItem?.itemRecursiveKey || removedItem.key, subCategory));

const findSubItemsToRemove = (newProductItems, categoriesToRemove) => !categoriesToRemove?.length
  ? []
  : categoriesToRemove.flatMap(categoryKey =>
    Object.values(newProductItems)
      .filter(item => categoryKey && item?.categoryKey === categoryKey)
      .flatMap(item => item?.options ? Object.values(item.options) : null)
      .filter(itemOption => !!itemOption)
  )

const handleRemovedItemOptions = (
  itemPositionKey,
  itemKeysToRemove,
  removedOptionRecursiveKey,
  eraseItemCounter,
  item,
  newItemsCounter,
  shouldUpdateItemsCounter,
  linkBetweenOptionsAndSubOptions,
  parentCategoryKey
) => {
  if (item?.options) {
    _.forEach(item.options, (option, optionGeneratedId) => {
      if (removedOptionRecursiveKey && option?.itemRecursiveKey === removedOptionRecursiveKey) {
        itemKeysToRemove.set(
          itemPositionKey,
          [...(itemKeysToRemove.get(itemPositionKey) || []), optionGeneratedId]
        );
        if (linkBetweenOptionsAndSubOptions?.has(parentCategoryKey)) {
          const subOptionFullKeys = option?.subcategories?.split(CATEGORY_SEPARATOR)?.map(
            (subcategoryKey) => generateSubcategoryKey(option.itemRecursiveKey, subcategoryKey)
          );
          linkBetweenOptionsAndSubOptions.set(
            parentCategoryKey,
            linkBetweenOptionsAndSubOptions.get(parentCategoryKey)
              .filter(subOp => !subOptionFullKeys?.includes(subOp))
          );
        }
        const counterOptionKey = generateCounterOptionKey(item?.categoryKey, option.key);
        if (eraseItemCounter && newItemsCounter[counterOptionKey] !== undefined) {
          delete newItemsCounter[counterOptionKey];
          shouldUpdateItemsCounter = true;
        }
      }
    })
  }
  return shouldUpdateItemsCounter;
}

const determineImpactedItemsAndRespectiveCounter = (
  removedItem,
  newProductItems,
  newItemsCounter,
  itemRecursiveKey,
  linkBetweenOptionsAndSubOptions,
  shopCategories,
  productCategories,
  itemKeysToRemove = new Map(),
  shouldUpdateItemsCounter = false,
  eraseItemCounter = false
) => {
  const categoriesToRemove = generateCategoriesToRemove(removedItem, itemRecursiveKey);
  const subItemsToRemove = findSubItemsToRemove(newProductItems, categoriesToRemove);
  const parentCategoryKey = getCategoryFromItemRecursiveKey(itemRecursiveKey);
  for (const [key, item] of Object.entries(newProductItems)) {
    if (item?.categoryKey && item?.categoryKey === removedItem?.categoryKey) {
      shouldUpdateItemsCounter = handleRemovedItemOptions(
        key,
        itemKeysToRemove,
        itemRecursiveKey,
        eraseItemCounter,
        item,
        newItemsCounter,
        shouldUpdateItemsCounter,
        linkBetweenOptionsAndSubOptions,
        parentCategoryKey
      );
      break;
    }
  }
  if (categoriesToRemove.length) {
    const result = removeCategories(shopCategories, productCategories, categoriesToRemove);
    ({
      updatedProductCategories: productCategories,
      updatedShopCategories: shopCategories,
    } = result);
  }
  const subItemsProcessResult = processSubItemsToRemove(
    subItemsToRemove,
    newProductItems,
    newItemsCounter,
    shopCategories,
    productCategories,
    itemKeysToRemove,
    shouldUpdateItemsCounter,
    linkBetweenOptionsAndSubOptions
  );
  ({
    updatedProductCategories: productCategories,
    updatedShopCategories: shopCategories,
  } = subItemsProcessResult)
  return {
    itemKeysToRemove,
    updatedShouldUpdateItemsCounter: subItemsProcessResult?.updatedShouldUpdateItemsCounter,
    updatedProductCategories: productCategories,
    updatedShopCategories: shopCategories,
    updatedMandatoryCategories: getProductMandatoryCategories(shopCategories, productCategories)
  };
};

const processSubItemsToRemove = (
  subItemsToRemove,
  newProductItems,
  newItemsCounter,
  shopCategories,
  productCategories,
  itemKeysToRemove,
  shouldUpdateItemsCounter,
  linkBetweenOptionsAndSubOptions
) => {
  for (const item of subItemsToRemove) {
    const result = determineImpactedItemsAndRespectiveCounter(
      item,
      newProductItems,
      newItemsCounter,
      item.itemRecursiveKey,
      linkBetweenOptionsAndSubOptions,
      shopCategories,
      productCategories,
      itemKeysToRemove,
      shouldUpdateItemsCounter,
      true
    );
    shouldUpdateItemsCounter = result?.updatedShouldUpdateItemsCounter || shouldUpdateItemsCounter;
    ({
      updatedProductCategories: productCategories,
      updatedShopCategories: shopCategories
    } = result)
  }
  return {
    shouldUpdateItemsCounter,
    updatedProductCategories: productCategories,
    updatedShopCategories: shopCategories
  };
}

export const handleRemovedItemSubCategories = (
  removedItem,
  newProductItems,
  newItemsCounter,
  itemRecursiveKey,
  eraseItemCounter,
  linkBetweenOptionsAndSubOptions = new Map(),
  shopCategories,
  productCategories
) => {
  const result = determineImpactedItemsAndRespectiveCounter(
    removedItem,
    newProductItems,
    newItemsCounter,
    itemRecursiveKey,
    linkBetweenOptionsAndSubOptions,
    shopCategories,
    productCategories,
    new Map(),
    false,
    eraseItemCounter
  );
  ({
    updatedProductCategories: productCategories,
    updatedShopCategories: shopCategories,
  } = result);
  result?.itemKeysToRemove?.forEach((optionKeys, key) => {
    optionKeys?.forEach(optionKey => {
      delete newProductItems[key]?.options?.[optionKey];
    })
    if (!_.keys(newProductItems[key]?.options)?.length) {
      delete newProductItems[key];
    }
  })
  return {
    shouldUpdateItemsCounter: result?.updatedShouldUpdateItemsCounter,
    productCategories,
    shopCategories,
    mandatoryCategories: result?.updatedMandatoryCategories,
    items: newProductItems
  };
}

export const generateProductItem = (
  category,
  index,
  productItems,
  itemRecursiveKey,
  item
) => {
  const itemId = generateId();
  const addedItem = {
    ...item,
    label: item.name,
    value: item.position,
    itemRecursiveKey
  };
  return {
    categoryKey: item.categoryKey,
    categoryPosition: index,
    ref: category.ref,
    categoryName: category.name,
    categoryMultiple: category.multiple,
    options: { ...({ ...productItems?.[index]?.options }), [itemId]: addedItem }
  };
}

export const getMinimumStockRemaining = component => {
  let result = {};
  const { items, nb, unit = false } = component.state;
  const { product } = component.props;
  let productStock = product.stock;
  let minimumStock = false;
  _.map(items, category => {
    if (category) {
      _.map(category.options, option => {
        const product = getOptionAssociatedProduct(option);
        if (product) {
          const optionStock = product.stock;
          const nbOption = getCurrentNbOption(items, option.key);
          const nbInCart = getNbInCartOfProduct(option.key, option.productKey);
          let stockRemaining = getStockRemaining(optionStock, nbInCart, nb * nbOption);
          if (
            stockRemaining > 0 &&
            getStockRemaining(optionStock, nbInCart, (nb + 1) * nbOption) < 0
          ) {
            stockRemaining = 0;
          }
          if (
            option.key &&
            parseInt(stockRemaining, 10) >= 0 &&
            (minimumStock === false || stockRemaining < minimumStock)
          ) {
            minimumStock = stockRemaining;
            result = {
              stock: optionStock,
              name: option.name,
              key: option.key,
              stockRemaining
            };
          }
        }
      });
    }
  });
  let nbInCart = getNbInCartOfProduct(product.key);
  if (unit) {
    productStock *= unit.conversion;
    nbInCart = 0;
  }
  const stockRemaining = getStockRemaining(productStock, nbInCart, nb);
  if (
    product.key &&
    Number.isInteger(stockRemaining) &&
    (minimumStock === false || stockRemaining < minimumStock)
  ) {
    result = { stock: productStock, name: product.name, key: product.key, stockRemaining };
  }
  return result;
};

const getOrderedSubOptions = (subOptions, parentCategory) => subOptions?.sort((subOpt1, subOpt2) => {
  const itemKey1 = getItemOriginalKeyFromSubItemRecursiveKey(subOpt1);
  const itemKey2 = getItemOriginalKeyFromSubItemRecursiveKey(subOpt2);
  return parentCategory?.items?.[itemKey1]?.position - parentCategory?.items?.[itemKey2]?.position
})

const findLastIndexOfCommonSubOptions = (
  parentCategory,
  subOptionKey,
  parentCategoryIndex,
  linkBetweenOptionsAndSubOptions,
  parentCategoryKey,
  productCategories
) => {
  const subOptions = linkBetweenOptionsAndSubOptions?.get(parentCategoryKey);
  if (!subOptions || subOptions?.length <= 1) {
    return parentCategoryIndex;
  }
  const orderedSubOptions = getOrderedSubOptions(subOptions, parentCategory);
  const subOptionPosition = orderedSubOptions.indexOf(subOptionKey);
  if (subOptionPosition === NOT_FOUND_INDEX) {
    return parentCategoryIndex;
  }
  const previousSubOptionKey = orderedSubOptions[subOptionPosition - 1];
  const lastPositionOfDepedenciesOfThePreviousSubOption = productCategories?.findLastIndex(category =>
    category && category?.includes(previousSubOptionKey)
  );
  return lastPositionOfDepedenciesOfThePreviousSubOption !== NOT_FOUND_INDEX
    ? lastPositionOfDepedenciesOfThePreviousSubOption
    : parentCategoryIndex;
}


const createModifiedShopCategory = ({
  shopCategory,
  categoryToAdd,
  parentItemLabel,
  parentItemRecursiveKey,
  isMultiple
}) => {
  const modifiedShopCategory = _.cloneDeep(shopCategory);
  modifiedShopCategory.originalName = shopCategory?.originalName || shopCategory.name;
  if (parentItemRecursiveKey && parentItemLabel) {
    modifiedShopCategory.name = formatCategoryName({
      shopCategory,
      parentItemLabel,
      parentItemRecursiveKey,
      isMultiple
    });
    modifiedShopCategory.key = generateSubcategoryKey(parentItemRecursiveKey, categoryToAdd);
  } else {
    modifiedShopCategory.key = categoryToAdd;
  }
  return modifiedShopCategory;
}

const updateSortedCategories = ({
  shopCategories,
  productCategories,
  modifiedShopCategory,
  parentCategoryKey,
  linkBetweenOptionsAndSubOptions,
  categoryToAddPosition,
  newSubcategories
}) => {
  if (parentCategoryKey) {
    const parentCategoryIndex = productCategories?.indexOf(parentCategoryKey);
    if (parentCategoryIndex !== NOT_FOUND_INDEX) {
      linkBetweenOptionsAndSubOptions.set(
        parentCategoryKey,
        [...new Set([...(linkBetweenOptionsAndSubOptions.get(parentCategoryKey) || []), modifiedShopCategory.key])]
      );
      const lastIndex = findLastIndexOfCommonSubOptions(
        shopCategories[parentCategoryKey],
        modifiedShopCategory.key,
        parentCategoryIndex,
        linkBetweenOptionsAndSubOptions,
        parentCategoryKey,
        productCategories
      );
      productCategories.splice(lastIndex + 1, 0, modifiedShopCategory.key);
    } else {
      newSubcategories.push(modifiedShopCategory.key);
    }
  } else if (categoryToAddPosition) {
    productCategories.splice(categoryToAddPosition, 0, modifiedShopCategory.key);
  } else {
    productCategories.push(modifiedShopCategory.key);
  }
}

const processCategoryToAdd = (
  categoryToAdd,
  shopCategories,
  parentItemRecursiveKey,
  parentItemLabel,
  isMultiple,
  linkBetweenOptionsAndSubOptions,
  newSubcategories,
  categoryToAddPosition,
  productCategories
) => {
  const categoryKey = categoryToAdd?.split(ITEM_KEY_COUNTER_SEPARATOR)?.[0];
  const shopCategory = shopCategories[categoryKey]
  if (!shopCategory) {
    return;
  }
  const modifiedShopCategory = createModifiedShopCategory({
    shopCategory,
    categoryToAdd,
    parentItemLabel,
    parentItemRecursiveKey,
    isMultiple
  });
  shopCategories[modifiedShopCategory.key] = modifiedShopCategory;
  updateSortedCategories({
    shopCategories,
    productCategories,
    modifiedShopCategory,
    parentCategoryKey: getCategoryFromItemRecursiveKey(parentItemRecursiveKey),
    linkBetweenOptionsAndSubOptions,
    categoryToAddPosition,
    newSubcategories
  });
}

export const addCategories = (
  shopCategories,
  productCategories,
  linkBetweenOptionsAndSubOptions,
  categoriesToAdd,
  categoryKey,
  parentItemLabel,
  parentItemRecursiveKey,
  isMultiple
) => {
  // add subcategories from checked item
  sendCloudWatchLogs(`Categories to add: ${categoriesToAdd}`);
  const newSubcategories = [];
  const categoryToAddPosition = categoryKey
    ? _.findIndex(productCategories, productCategoryKey => categoryKey &&
      productCategoryKey === categoryKey
    ) + 1
    : productCategories.length;
  _.forEach(categoriesToAdd, categoryToAdd => {
    processCategoryToAdd(
      categoryToAdd,
      shopCategories,
      parentItemRecursiveKey,
      parentItemLabel,
      isMultiple,
      linkBetweenOptionsAndSubOptions,
      newSubcategories,
      categoryToAddPosition,
      productCategories
    )
  })
  const updatedProductCategories = productCategories.concat(newSubcategories)
  const { shopCategories: updatedShopCategories } = updateShopCategoriesWithProductCategories(
    shopCategories,
    updatedProductCategories
  );
  return {
    shopCategories: updatedShopCategories,
    productCategories: updatedProductCategories,
    mandatoryCategories: getProductMandatoryCategories(shopCategories, updatedProductCategories),
    linkBetweenOptionsAndSubOptions
  };
};

export const removeCategories = (shopCategories, productCategories, categoriesToRemove) => {
  sendCloudWatchLogs(`Categories to remove: ${categoriesToRemove}`);
  _.forEach(productCategories, (category, index) => {
    if (!category) {
      return;
    }
    if (categoriesToRemove?.includes(category)) {
      delete productCategories[index];
      delete shopCategories[category];
    }
  });
  const { shopCategories: updatedShopCategories } = updateShopCategoriesWithProductCategories(shopCategories, productCategories);
  return {
    updatedProductCategories: productCategories?.filter(productCategory => !!productCategory),
    updatedShopCategories
  };
};

export const sortProductItemsAccordingToSortedProductCategories = (
  productCategories,
  productItems
) =>
  productCategories?.map((category, index) => {
    const matchingProduct = _.find(productItems, productItem => productItem?.categoryKey === category);
    return matchingProduct
      ? { ...matchingProduct, categoryPosition: index, categoryName: matchingProduct.categoryName.split(' - ').pop() }
      : null;
  }) || [];

export const updateSubCategories = (
  items,
  updatedShopCategories,
  updatedProductCategories,
  updatedMandatoryCategories,
  updatedLinkBetweenOptionsAndSubOptions,
  newItem,
  itemRecursiveKey,
  isMultiple = true
) => {
  const productItemToRemove = _.find(items, productItem => productItem?.categoryKey === newItem?.categoryKey);
  if (newItem.subcategories) {
    // add subcategories from selected/checked item
    const categoriesToAdd = newItem.subcategories.split(CATEGORY_SEPARATOR);
    const categoriesUpdateResult = addCategories(
      updatedShopCategories,
      updatedProductCategories,
      updatedLinkBetweenOptionsAndSubOptions,
      categoriesToAdd,
      newItem?.categoryKey,
      newItem?.name,
      itemRecursiveKey || newItem?.key,
      isMultiple
    );
    ({
      shopCategories: updatedShopCategories,
      productCategories: updatedProductCategories,
      mandatoryCategories: updatedMandatoryCategories,
      linkBetweenOptionsAndSubOptions: updatedLinkBetweenOptionsAndSubOptions
    } = categoriesUpdateResult);
  }
  return {
    items: sortProductItemsAccordingToSortedProductCategories(updatedProductCategories, items),
    productItemToRemove,
    shopCategories: updatedShopCategories,
    productCategories: updatedProductCategories,
    mandatoryCategories: updatedMandatoryCategories,
    linkBetweenOptionsAndSubOptions: updatedLinkBetweenOptionsAndSubOptions
  };
};

export const isNextCategoryDisabled = component => {
  const { items, mandatoryCategories, stepOptionIndex, productCategories, shopCategories } = component.state;
  const categoriesLength = productCategories.length + 1;
  const mandatoryCategory = _.find(mandatoryCategories, mCategory => {
    return mCategory === stepOptionIndex;
  });
  if (mandatoryCategory >= 0 && !items[mandatoryCategory]) {
    return true;
  }
  const categoryId = productCategories[mandatoryCategory];
  if (categoryId) {
    const category = shopCategories[categoryId];
    if (
      category?.min &&
      items[mandatoryCategory] &&
      _.size(items?.[mandatoryCategory]?.options) < category.min
    ) {
      return true;
    }
  }
  if (stepOptionIndex + 1 === categoriesLength) {
    return 'lastItem';
  }
  return false;
};

export const handlePreviousOption = component => {
  const { stepOptionIndex } = component.state;
  component.setState({
    stepOptionIndex: stepOptionIndex - 1
  });
};

export const handleNextOption = component => {
  const { stepOptionIndex } = component.state;
  component.setState({
    stepOptionIndex: stepOptionIndex + 1
  });
};

export const updateShopCategoriesWithProductCategories = (shopCategories = {}, productCategories) => {
  const updatedShopCategories = _.cloneDeep(shopCategories);
  productCategories?.forEach(productCategory => {
    const shopCategory = updatedShopCategories?.[productCategory];
    if (!shopCategory) {
      const productCategoryWithoutIndex = productCategory?.split(ITEM_KEY_COUNTER_SEPARATOR)?.[0];
      const originalShopCategory = updatedShopCategories?.[productCategoryWithoutIndex]
      if (originalShopCategory) {
        updatedShopCategories[productCategory] = {
          ...originalShopCategory,
          key: productCategory
        };
      }
    }
  })
  return { shopCategories: removeUndefinedProperties(updatedShopCategories) };
}

export const getProductMandatoryCategories = (shopCategories, newCategories) => {
  const mandatoryCategories = [];
  _.forEach(shopCategories, (shopCategory, shopCategoryKey) => {
    let categoryIndexToAdd;
    const isMandatoryCategory = newCategories?.some((productCategory, categoryIndex) => {
      categoryIndexToAdd = categoryIndex;
      return (
        shopCategory?.key === productCategory ||
        productCategory?.split(ITEM_KEY_COUNTER_SEPARATOR)?.[0] === shopCategoryKey
      ) &&
        shopCategory?.min &&
        !mandatoryCategories.includes(categoryIndex)
    }
    )
    if (isMandatoryCategory) {
      mandatoryCategories.push(categoryIndexToAdd);
    }
  });
  return mandatoryCategories;
};

export const getProductData = (shopCategories, product, productCategories) => {
  let { unit = null } = product;
  const units = getProductUnits(unit);
  if (units) {
    unit = units[0];
  }
  const { shopCategories: updatedShopCategories } = updateShopCategoriesWithProductCategories(
    shopCategories,
    productCategories
  );
  const mandatoryCategories = getProductMandatoryCategories(updatedShopCategories, productCategories);
  return { shopCategories: updatedShopCategories, productCategories, mandatoryCategories, unit, nb: unit ? 0 : 1 };
};
export const getOptionsData = (items) => {
  _.map(items, (item) => {
    if (item) {
      _.map(item.options, option => {
        if (option.isFree) {
          option.price = 0;
        }
      });
    }
  });
  return items
}