import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import styles from './Product.styles'
import { showAddressModal, showMessageModal } from '../../../redux/actions'
import { IMAGE_ASPECT_COVER, IMAGE_UNAVAILABLE_IMAGE_URL, PRODUCT_STYLE_LIST } from '../../../constants'
import { UNIT } from '../../../constants/configuration'

import {
  displayPriceFormat,
  getMinimumPriceFreeProduct,
  getNbInCartWithKey,
  isChatbot,
  isProductDisabled,
  isStringNotNull,
  isTerminal,
  isTerminalVertical
} from '../../../utils'
import ProductModal from '../ProductModal'
import { sendGoogleAnalytics } from '../../../api'
import { CUSTOMER_ADDRESS_CHECK_VALUES } from '../../../redux/reducers/componentReducer'
import { isCustomerAddressDefined } from '../../../utils/customer-address-and-shops'

const INITIAL_STATE = {
  showModal: false,
  cartNb: 0
};

class Product extends React.Component {
  componentDidMount() {
    const { commande, product } = this.props;
    if (!_.isEmpty(commande)) {
      const { key } = product;
      const cartNb = getNbInCartWithKey(key);
      this.setState({ cartNb });
    } else {
      this.setState({ cartNb: 0 });
    }
  }

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  hideModal = () => {
    this.setState({ showModal: false });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { product } = this.props;
    if (!_.isEmpty(nextProps.commande)) {
      const { key } = product;
      const cartNb = getNbInCartWithKey(key);
      this.setState({ cartNb });
    } else if (_.isEmpty(nextProps.commande)) {
      this.setState({ cartNb: 0 });
    }
  }

  renderProductPrice() {
    const { product } = this.props;
    const { price, unit } = product;
    const minimumPrice = getMinimumPriceFreeProduct(product);
    if (price > 0 || minimumPrice > 0) {
      if (price > 0) {
        return (
          <h6
            className={`${isTerminalVertical() ? 'text-muted' : 'text-product text-muted'}`}
            style={isTerminalVertical() ? { fontSize: UNIT * 1.5 } : {}}
          >
            {displayPriceFormat(price)}
            {unit ? `/${unit}` : ''}
          </h6>
        );
      }
      return (
        <h6
          className='text-product text-muted font-italic'
          style={isTerminalVertical() ? { fontSize: UNIT * 1.25 } : {}}
        >
          À partir de {displayPriceFormat(minimumPrice)}
          {isStringNotNull(unit) ? `/${unit}` : ''}
        </h6>
      );
    }
  }

  renderProductCardForTerminalVertical(title) {
    const imageProductOptionClass = 'img-fluid float-left px-2 pt-2';
    const { image, secondaryColor, images } = this.props;
    const { aspect } = images;
    const imageUrl = !isStringNotNull(image) ? IMAGE_UNAVAILABLE_IMAGE_URL : image;
    return (
      <div className='row justify-content-center'>
        {aspect === IMAGE_ASPECT_COVER && (
          <div
            className='img-fluid float-left'
            style={{
              ...styles.productPictureTerminalVertical,
              borderTopLeftRadius: UNIT * 2,
              borderTopRightRadius: UNIT * 2,
              width: '100%',
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          />
        )}
        {aspect !== IMAGE_ASPECT_COVER && (
          <img
            src={imageUrl}
            alt={title}
            className={imageProductOptionClass}
            style={{ ...styles.productPictureTerminalVertical, borderRadius: UNIT * 2 }}
          />
        )}
        <div className='col-12'>
          <h3 className='d-flex justify-content-center align-items-end' style={{ height: '80px' }}>
            {title}
          </h3>
          <hr
            className='my-4 hr'
            style={{
              width: '70%',
              backgroundColor: secondaryColor,
              border: `1px solid ${secondaryColor}`
            }}
          />
          {this.renderProductPrice()}
        </div>
      </div>
    );
  }

  renderProductImage() {
    const { image, isHorizontalSection, productStyle, title, images } = this.props;
    const imageProductGridClass = isHorizontalSection
      ? 'image-product-grid-horizontal'
      : 'image-product-grid-vertical';
    const imageProductListClass = isHorizontalSection
      ? 'image-product-list-horizontal'
      : 'image-product-list-vertical';
    const imageUrl = !isStringNotNull(image) ? IMAGE_UNAVAILABLE_IMAGE_URL : image;
    const { aspect } = images;
    if (productStyle === PRODUCT_STYLE_LIST) {
      const imageProductClassname = `col-5 img-fluid float-left ${imageProductListClass} `;
      if (aspect === IMAGE_ASPECT_COVER) {
        return (
          <div
            className={imageProductClassname}
            style={{
              ...styles.productPictureBorder,
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          />
        );
      }
      return (
        <img
          src={imageUrl}
          alt={title}
          className={imageProductClassname}
          style={styles.productPictureBorder}
        />
      );
    }
    const imageProductClassname = `img-fluid col-12 ${imageProductGridClass}`;
    if (aspect === IMAGE_ASPECT_COVER) {
      return (
        <div
          className={imageProductClassname}
          style={{
            ...styles.productPicture,
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
      );
    }
    return (
      <img
        src={imageUrl}
        alt={title}
        className={imageProductClassname}
        style={styles.productPicture}
      />
    );
  }

  renderProductCard() {
    const maxDescription = 60;
    const maxTitle = 30;
    let { title, description } = this.props;
    const { productStyle, isHorizontalSection } = this.props;
    const imageProductGridClass = isHorizontalSection
      ? 'image-product-grid-horizontal'
      : 'image-product-grid-vertical';
    const imageProductListClass = isHorizontalSection
      ? 'image-product-list-horizontal'
      : 'image-product-list-vertical';

    title?.length > 0 ? `${(title[0].toUpperCase() + title.slice(1).toLowerCase()).substring(0, maxTitle)}${
      title.length > maxTitle ? '...' : ''
    }` : '';
    description = `${
      description
        ? `${description.substring(0, maxDescription)}${
          description.length > maxDescription ? '...' : ''
        }`
        : ''
    }`;
    if (isTerminalVertical()) {
      return <>{this.renderProductCardForTerminalVertical(title)}</>;
    }
    if (productStyle === PRODUCT_STYLE_LIST) {
      return (
        <div className={`row ${imageProductListClass}`}>
          {this.renderProductImage()}
          <div className='col-7 text-left pl-3 my-auto'>
            <h5 className='text-product label'>{title}</h5>
            <p className='text-description mt-2 mb-1 label'>{description}</p>
            {this.renderProductPrice()}
          </div>
        </div>
      );
    }
    return (
      <>
        <div className={imageProductGridClass}>{this.renderProductImage()}</div>
        <div className='py-2'>
          <h6 className='text-product px-1'>{title}</h6>
          {this.renderProductPrice()}
          <p className='text-description mb-0 px-2'>{description}</p>
        </div>
      </>
    );
  }

  renderModal = () => {
    const { product, cle, section, productKey } = this.props;
    const { name, key } = section;
    const { showModal } = this.state;
    let categories = [];
    if (product.categories) {
      categories = product.categories.split(',');
    }
    const buttonName = !isChatbot() ? 'Ajouter' : `Plus d'informations`;
    return (
      <div className='mt-2 mt-sm-3'>
        <ProductModal
          product={product}
          title={product.name}
          image={product.image}
          price={product.price}
          description={product.description}
          cle={cle}
          categories={categories}
          sectionName={name}
          buttonName={buttonName}
          showModal={showModal}
          productKey={productKey}
          sectionKey={key}
          onHideModal={() => this.hideModal()}
        />
      </div>
    );
  };

  render() {
    const {
      customerAddressCheck,
      product,
      showAddressModal,
      secondaryColor,
      multiOrder,
      orders,
      showMessageModal,
      customerAddress,
      section,
      messagesSectionRange
    } = this.props;
    const { name, key, price, position } = product;
    const { cartNb } = this.state;
    const productCardStyle = _.clone(styles.productCard);
    const verticalTerminalProductCard = _.clone(styles.verticalTerminalProductCard);
    if (cartNb > 0) {
      productCardStyle.border = `2px solid ${secondaryColor}`;
      verticalTerminalProductCard.border = `4px solid ${secondaryColor}`;
    }
    const productDisabled = isProductDisabled(product) || messagesSectionRange?.[section.key]
    return (
      <>
        <div
          className={`card text-center my-1 my-sm-3 productCard ${!isTerminalVertical() &&
            'shadow'}`}
          style={isTerminalVertical() ? verticalTerminalProductCard : productCardStyle}
          onClick={() => {
            if (!productDisabled) {
              if (!isChatbot() && (
                customerAddressCheck !== CUSTOMER_ADDRESS_CHECK_VALUES.VALID ||
                !isCustomerAddressDefined(customerAddress))
              ) {
                showAddressModal(true);
              } else if (!_.isEmpty(orders) && !multiOrder && !isTerminal()) {
                showMessageModal(
                  `Vous ne pouvez pas ajouter de produits car vous avez déjà ${
                    _.size(orders) > 1 ? `${_.size(orders)} commandes` : 'une commande'
                  } en cours`
                );
              } else {
                sendGoogleAnalytics('view_item', {
                  items: [
                    {
                      id: key,
                      name,
                      price,
                      position,
                      category: section.name
                    }
                  ],
                  currency: 'EUR',
                  value: price
                });
                this.setState({ showModal: true });
              }
            }
          }}
        >
          {cartNb > 0 && (
            <div
              style={{
                position: 'absolute',
                height: isTerminalVertical() ? '50px' : '40px',
                width: isTerminalVertical() ? '50px' : '40px',
                right: -10,
                top: -10,
                zIndex: 1000,
                borderRadius: UNIT * 2,
                backgroundColor: secondaryColor
              }}
            >
              <div
                className='text-white d-flex align-items-center justify-content-center'
                style={{
                  height: '100%',
                  fontSize: isTerminalVertical() ? UNIT * 1.875 : UNIT * 1.25
                }}
              >
                {cartNb}
              </div>
            </div>
          )}

          {productDisabled && (
            <div
              className='overlay'
              style={{ borderRadius: isTerminalVertical() ? UNIT * 2 : UNIT }}
            >
              <div className='text-overlay'>
                <h6 className='text-white disabledTitle mb-0 font-weight-bold'>
                  Produit Indisponible
                </h6>
              </div>
            </div>
          )}
          {this.renderProductCard()}
        </div>
        {this.renderModal()}
      </>
    );
  }
}

const mapStateToProps = ({
  configurationReducer,
  componentReducer,
  pendingOrderReducer,
  orderReducer
}) => {
  const { webapp, productStyle, theme, secondaryColor, multiOrder, images } = configurationReducer;
  const { backgroundSize } = webapp;
  const { isHorizontalSection, customerAddressCheck } = componentReducer;
  const { orders } = orderReducer;
  const { commande, address: customerAddress } = pendingOrderReducer;
  return {
    customerAddressCheck,
    theme,
    backgroundSize,
    productStyle,
    isHorizontalSection,
    secondaryColor,
    commande,
    multiOrder,
    orders,
    images,
    customerAddress
  };
};

export default connect(mapStateToProps, { showAddressModal, showMessageModal })(Product);