import { FETCH_CONFIGURATION, UPDATE_LOYALTY } from '../actions/types'
import { PRODUCT_STYLE_GRID, SECTION_VERTICAL, } from '../../constants'

const INITIAL_STATE = {
  companyId: null,
  secondaryColor: null,
  name: null,
  webapp: {
    images: {}
  },
  cgu: '',
  logoUrl: null,
  appleStoreId: null,
  playStoreId: null,
  orderTypes: [],
  paymentTypes: [],
  stuart: false,
  theme: null,
  sectionOrientation: SECTION_VERTICAL,
  productStyle: PRODUCT_STYLE_GRID,
  qrcode: {},
  terminal: {},
  google: {},
  stripeApiKey: null,
  landing: {},
  chatbot: {},
  servicePrice: {},
  delivery: {},
  facebook: {},
  multiOrder: false,
  disableProductInstruction: false,
  marketplace: {},
  images: {},
  imagesDescription: '',
  slotInterval: 30,
  configHours: {},
  cancel: {},
  descriptionText: null,
  managementFeeObj: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONFIGURATION:
      const { webapp, google, descriptionText } = action.payload;
      return {
        ...state,
        images: action.payload.images || {},
        secondaryColor: action.payload.secondaryColor,
        name: action.payload.name,
        companyId: action.payload.id,
        cgu: action.payload.cgu,
        imagesDescription: action.payload.imagesDescription,
        appleStoreId: action.payload.appleStoreId,
        playStoreId: action.payload.playStoreId,
        logoUrl: action.payload.logoUrl,
        webapp,
        sectionOrientation:
          webapp?.sectionOrientation || SECTION_VERTICAL,
        productStyle: webapp?.productStyle || PRODUCT_STYLE_GRID,
        orderTypes: action.payload.orderTypes,
        paymentTypes: action.payload.paymentTypes,
        stuart: action.payload.stuart,
        theme: action.payload.theme,
        terminal: action.payload.terminal,
        qrcode: action.payload.qrcode,
        chatbot: action.payload.chatbot || {},
        socials: action.payload.socials || {},
        slides: action.payload.slides || {},
        stripeApiKey: action.payload.stripeApiKey,
        landing: webapp?.landing || {},
        delivery: action.payload.delivery || {},
        facebook: action.payload.facebook || {},
        multiOrder: action.payload.multiOrder || false,
        google: google || {},
        disableProductInstruction: action.payload.disableProductInstruction,
        localize: action.payload.localize || {},
        servicePrice: action.payload.servicePrice || {},
        loyalty: action.payload.loyalty || {},
        marketplace: action.payload.marketplace || {},
        configHours: action.payload.hours || {},
        cancel: action.payload.cancel || {},
        managementFeeObj: action.payload.managementFee || {},
        descriptionText
      };
      case UPDATE_LOYALTY:
      return {
        ...state,
        loyalty: action.payload
      };
    default:
      return state;
  }
};
