import { CardElement } from '@stripe/react-stripe-js'
import { addCustomerPaymentCardBackend, sendGetRequest, sendPostRequest } from './backend'
import { decrypt, sendCloudWatchAlert, sendCloudWatchLogs } from '../utils'
import store from '../redux/store'
import { showMessageModal } from '../redux/actions'
import {
  API_ADD_CARD_CUSTOMER,
  API_CREATE_CUSTOMER_STRIPE,
  API_CREATE_PAYMENT_INTENT,
  API_GET_CUSTOM_STRIPE_API_KEY,
  API_PAYMENT_CONFIRM,
  API_RETRIEVE_CARD,
  API_UPDATE_DEFAULT_SOURCE_CUSTOMER
} from '../constants'
import { getIdToken } from './firebase/firebaseAuthentication'

export const getStripeCustomApiKey = async (companyId) => {
  try {
 const idToken = await getIdToken();
  const headers = { Authorization: `Bearer ${idToken}` };
  const cryptedApiKEy = (
    await sendGetRequest(API_GET_CUSTOM_STRIPE_API_KEY(companyId), {}, headers)
  )?.data;
  return decrypt(cryptedApiKEy);
} catch (error) {
  sendCloudWatchLogs(`Error getting stripe custom api key: ${JSON.stringify(error)}`);
}
}

export const retrieveCard = async data => {
  const idToken = await getIdToken();
  const headers = { Authorization: `Bearer ${idToken}` };
  const card = await sendPostRequest(API_RETRIEVE_CARD, data, headers);
  return card.data;
};

export const updateDefaultCard = async data => {
  const idToken = await getIdToken();
  const headers = { Authorization: `Bearer ${idToken}` };
  await sendPostRequest(API_UPDATE_DEFAULT_SOURCE_CUSTOMER, data, headers);
  return true;
};

export const createPayment = async data => {
  const { userConnected } = store.getState().userReducer;
  let headers = {};
  if (userConnected) {
    const idToken = await getIdToken();
    headers = { Authorization: `Bearer ${idToken}` };
  }
  const charge = await sendPostRequest(API_CREATE_PAYMENT_INTENT, data, headers);
  return charge.data;
};
export const confirmPaymentIntent = async paymentIntentId => {
  const data = {
    paymentIntentId
  };
  const idToken = await getIdToken();
  const headers = { Authorization: `Bearer ${idToken}` };
  const response = await sendPostRequest(API_PAYMENT_CONFIRM, data, headers);
  return response;
};

export const createCustomer = async data => {
  const idToken = await getIdToken();
  const headers = { Authorization: `Bearer ${idToken}` };
  await sendPostRequest(API_CREATE_CUSTOMER_STRIPE, data, headers);
};

export const addCardToExistingCustomer = async data => {
  const idToken = await getIdToken();
  const headers = { Authorization: `Bearer ${idToken}` };
  await sendPostRequest(API_ADD_CARD_CUSTOMER, data, headers);
};

export const addCustomerCard = async (component, stripe, elements) => {
  try {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return false
    }
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });
    if (error) {
      sendCloudWatchLogs(`Error adding credit card from stripe: ${JSON.stringify(error)}`);
      store.dispatch(showMessageModal(error.message));
      return false;
    }
    const { userConnected } = store.getState().userReducer;
    const { card, id } = paymentMethod;
    const newCard = card;
    newCard.id = id;
    newCard.name = `XXXX XXXX XXXX ${card.last4}`;
    newCard.selected = true;
    sendCloudWatchLogs(`Add new credit card ${id}`);
    if (userConnected) {
      await addCustomerPaymentCardBackend(newCard);
    }
    return newCard;
  } catch (error) {
    sendCloudWatchAlert(`Error adding credit card: ${error}`);
    store.dispatch(
      showMessageModal('Nous n\'avons pas pu ajouter votre carte bancaire. Veuillez réessayer')
    );
    return false;
  }
};
