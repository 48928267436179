import { Cookies } from 'react-cookie'
import _ from 'lodash'
import apiService from '@dishopsaas/dishop-backend-api-service'
import store from '../redux/store'
import { anonymousUserConnexion, getFirebaseCustomerId } from '../api/firebase/firebaseAuthentication'
import { createCustomerBackend } from '../api/backend'
import { getShopIdFromLink } from './shop'
import { COOKIE_CUSTOMER_ID, LOYALTY, WEBAPP } from '../constants'
import { isStringNotNull } from './dataType'
import moment from 'moment'
import { setUserPendingOrderAddressAndOrderTypeFirebase } from './customer-address-and-shops'
import { userConnectionSuccess } from '../redux/actions'

export const getCreditCard = () => {
  const { user } = store.getState().userReducer;
  const { cards } = user;
  if (cards) {
    return _.find(cards, card => {
      return card.selected;
    });
  }
}

export const checkMinBasketPrice = (codePromotion, totalPriceOrder) => {
  let minBasketPrice = null
  if (codePromotion === LOYALTY) {
    const { loyalty } = store.getState().configurationReducer;
    minBasketPrice = loyalty?.minBasketPrice;
  } else {
    const { promotions } = store.getState().shopReducer;
    minBasketPrice = promotions[codePromotion]?.minBasketPrice ;
  }
  if (!minBasketPrice || totalPriceOrder >= minBasketPrice) return null;
  else return minBasketPrice
}

export const isPromotionValid = promotion => {
  const { orderType, shopId } = store.getState().pendingOrderReducer;
  return (
    promotion &&
    (promotion.shopId ? promotion.shopId === shopId : true) &&
    (promotion?.canals ? promotion.canals?.includes(WEBAPP) : true) &&
    (promotion.orderTypes ? promotion.orderTypes.includes(orderType) : true)
  );
}

export const getPromotion = (totalPriceOrder) => {
  const { promotions } = store.getState().userReducer.user;
  const customerId = getFirebaseCustomerId();
  const { promotions: shopPromotions, shopId } = store.getState().shopReducer;
  let promoPath;
  const { orders } = store.getState().orderReducer;
  if (promotions) {
    const promotionData = _.find(promotions, (promo, ppath) => {
      const { promotion = {} } = promo;
      const { promoCode } = promotion;
      const promoCodeUsed = _.find(orders, order => {
        const { promotion } = order;
        return promotion && promotion.promoCode === promoCode;
      });
      promoPath = ppath;
      return promo.status === 'en cours' && !promoCodeUsed;
    });
    if (promotionData) {
      const { promotion } = promotionData;
      const minBasketPrice = checkMinBasketPrice(promotion?.promoCode, totalPriceOrder)
      if (promotion.promoCode === LOYALTY) {
        return { ...promotion, minBasketPrice } ;
      }
      const foundPromotion = shopPromotions[promotion.promoCode]
      const isExpired = foundPromotion?.expired;
      const isDateRangeInvalid = foundPromotion?.startedDate && foundPromotion?.endedDate &&
     !moment().isBetween(foundPromotion.startedDate, foundPromotion.endedDate, undefined, '[]');
      const isShopMatch = !promotion.shopId || promotion.shopId === shopId;
      if ((isExpired || isDateRangeInvalid) && isShopMatch) {
         apiService.usersDelete([customerId, 'promotions', promoPath]);
         apiService.devicesDelete([customerId, 'promoCodeUsed', promoPath]);
      } else if (isPromotionValid(foundPromotion)) {
        return { ...foundPromotion, minBasketPrice } ;
      }

    }
  }
}


export function validatePhone(telephone) {
  const re = /^[\s()+-]*([0-9][\s()+-]*){6,20}(?:[-. \\/]?(?:#|ext\.?|extension|x)[-. \\/]?(\d+))?$/i;
  return re.test(String(telephone).toLowerCase());
}

export const getCookieCustomerId = () => {
  return new Cookies().get(COOKIE_CUSTOMER_ID);
};

export const setCookieCustomerId = customerId => {
  const cookies = new Cookies();
  cookies.set(COOKIE_CUSTOMER_ID, customerId);
};

export const resetCookieCustomerId = () => {
  const cookies = new Cookies();
  cookies.remove(COOKIE_CUSTOMER_ID);
};

export const getWebViewCustomerId = () => {
  return window.location.pathname.split('/')[3];
};

export const getCustomerTableNb = () => {
  return window.location.pathname.split('/')[3];
};

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const generateCustomerAddress = address => {
  const newAddress = _.cloneDeep(address);
  const { latitude, longitude } = newAddress.location;
  newAddress.id = `${parseFloat(latitude).toFixed(6)}${parseFloat(longitude).toFixed(6)}`
    .replace(/\./g, '');
  newAddress.selected = true;
  return newAddress;
}

export const createCookieCustomer = async orderType => {
  const shopId = getShopIdFromLink();
  const address = await apiService.shopsGetOne([shopId, 'address']);
  let customerId = getCookieCustomerId();
  let customerExist = !!customerId;
  if (!customerExist) {
    customerId = await anonymousUserConnexion();
    const customerProfile = { id: customerId };
    customerExist = await createCustomerBackend(customerProfile);
  }
  const customerAddress = generateCustomerAddress(address);
  if (customerExist) {
    setCookieCustomerId(customerId);
    store.dispatch(async (dispatch, getState) => {
      await userConnectionSuccess(dispatch, getState, customerId, true);
    })
      .then(async () =>
        await setUserPendingOrderAddressAndOrderTypeFirebase(
          customerId,
          shopId,
          customerAddress,
          orderType
        )
      )
  }
  return customerId;
}

export const formatPhone = (phone, countryCode = '+33') => {
  if (isStringNotNull(phone)) {
    if (phone.startsWith('0') && phone.length > 1) {
      phone = phone.replace('0', countryCode);
    } else if (!phone.startsWith('+') && phone.length > 1) {
      phone = countryCode + phone;
    }
    phone = phone.replace(/ /g, '');
  }
  return phone;
};

export const phoneNumberMinLength = iso2 => {
  const minLengths = {
    fr: 9,
    be: 8,
    us: 10,
    no: 8,
    mc: 8,
    sw: 7,
    al: 8,
    de: 11
  };
  return minLengths[iso2] || 5;
};