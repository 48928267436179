import _ from 'lodash'
import React from 'react'
import {
  ORDER_TYPE_CLICK_AND_COLLECT,
  ORDER_TYPE_CLICK_AND_SEAT,
  ORDER_TYPE_DELIVERY,
  PRODUCT_KEY_ID_SEPARATOR,
  PRODUCT_UNITS
} from '../constants'
import store from '../redux/store'
import moment from 'moment';
import { isStringNotNull } from './dataType'
import { generateId, isObjectNotNull } from '../utils'
import { removeNullProperties, removeUndefinedProperties } from '../utils/FunctionUtils'

export const priceWithPercentage = (number, percentage) => {
  return parseFloat((parseFloat(number) + (number * percentage) / 100).toFixed(1));
};

export const getProductFees = (initialOrderType = null) => {
  let productFees = null;
  const { configurationReducer, pendingOrderReducer } = store.getState();
  const { servicePrice } = configurationReducer;
  const { address: customerAddress = {}, orderType } = pendingOrderReducer;
  const { networkFees = {} } = servicePrice;
  const { delivery = {} } = networkFees;
  if (networkFees.productFees) {
    productFees = servicePrice.networkFees.productFees;
  }
  const chosenOrderType = orderType || initialOrderType;
  if (customerAddress && chosenOrderType === ORDER_TYPE_DELIVERY && delivery.productFees) {
    productFees = delivery.productFees;
  }
  return productFees;
};

const deepOmit = (obj, propertiesToOmit) => {
  if (_.isArray(obj)) {
    return obj.map(item => deepOmit(item, propertiesToOmit));
  } else if (_.isObject(obj)) {
    return _.omitBy(_.mapValues(obj, value => deepOmit(value, propertiesToOmit)),
      (value, key) => propertiesToOmit.includes(key));
  }
  return obj;
};

const isSameProductItems = (firstProductItems, secondProductItems) => {
  const productItems1 = !_.isEmpty(firstProductItems)
    ? _.filter(firstProductItems, item => !!item)
    : null;
  const productItems2 = !_.isEmpty(secondProductItems)
    ? _.filter(secondProductItems, item => !!item)
    : null;
  const isBothEmpty = _.isEmpty(productItems1) && _.isEmpty(productItems2);
  if (isBothEmpty) {
    return true;
  }
  if (productItems1?.length !== productItems2?.length) {
    return false;
  }
  const ignoredProperty = [
    'nb',
    'height',
    'logo',
    'price',
    'width',
    'productKey',
    'categoryPosition'
  ];
  const optionsProperty = 'options';
  const product1Filtered = deepOmit(productItems1, [...ignoredProperty, optionsProperty]);
  const product2Filtered = deepOmit(productItems2, [...ignoredProperty, optionsProperty]);
  if (
    !_.isEqual(
     removeNullProperties(removeUndefinedProperties(product1Filtered)),
     removeNullProperties(removeUndefinedProperties(product2Filtered))
    )
  ) {
    return false;
  }
  const orderByItemRecursiveKey = () => (item1, item2) => item1?.itemRecursiveKey?.localeCompare(item2?.itemRecursiveKey);
  for (let i = 0; i < productItems1.length; i++) {
    const productItem1 = productItems1[i];
    const productItem2 = productItems2[i];
    if (
      !_.isEqual(
        Object.values(productItem1?.[optionsProperty])?.sort(orderByItemRecursiveKey()),
        Object.values(productItem2?.[optionsProperty])?.sort(orderByItemRecursiveKey())
      )
    ) {
      return false;
    }
  }
  return true;
};

const getProductIdFromPendingOrder = (productKey, addedItems, instruction) => {
  const { commande } = store.getState().pendingOrderReducer;
  return _.findKey(commande, (productInCart) => {
    if ((!instruction && !productInCart?.instruction) || _.isEqual(instruction, productInCart?.instruction)) {
        return productKey === productInCart.key && isSameProductItems(addedItems, productInCart?.items);
      }
    }
  );
};

const generateProductId = (productKey) =>
  productKey.includes(PRODUCT_KEY_ID_SEPARATOR)
    ? productKey
    : `${productKey}${PRODUCT_KEY_ID_SEPARATOR}${generateId()}`;

export const getProductId = (productKey, addedItems, instruction = undefined) => {
  const existingProductId = getProductIdFromPendingOrder(productKey, addedItems, instruction);
  return existingProductId || generateProductId(productKey);
};

export const getProductUnits = unit => {
  if (isStringNotNull(unit)) {
    const units = [{ name: unit, conversion: 1 }];
    if (PRODUCT_UNITS[unit.toLowerCase()]) {
      _.map(PRODUCT_UNITS[unit.toLowerCase()], productUnit => {
        units.push(productUnit);
      });
    }
    return units;
  }
  return null;
};

export const getNbInCart = productId => {
  const { pendingOrderReducer } = store.getState();
  let nbInCart = 0;
  _.map(pendingOrderReducer?.commande, (productInCart, id) => {
    if (productId === id) {
      nbInCart = productInCart.nb;
    }
  });
  return nbInCart;
};

export const getNbInCartWithKey = productKey => {
  const { pendingOrderReducer } = store.getState();
  let nbInCart = 0;
  _.map(pendingOrderReducer?.commande, productInCart => {
    const { unit, key, nb } = productInCart;
    if (productKey === key) {
      if (isStringNotNull(unit)) {
        nbInCart += 1;
      } else {
        nbInCart += nb;
      }
    }
  });
  return nbInCart;
};

export const getFirstProductCategory = product => {
  const { categories } = product;
  let firstShopCategory = null;
  const shopCategories = store.getState().shopReducer?.categories;
  if (categories) {
    const productCategories = categories.split(',');
    const categoryKey = productCategories[0].split('@')[0];
    firstShopCategory = shopCategories[categoryKey];
    if (firstShopCategory) {
      firstShopCategory.path = categoryKey;
    }
  }
  return firstShopCategory;
};

export const getMinimumPriceFreeProduct = product => {
  let minimumPrice = null;
  const firstShopCategory = getFirstProductCategory(product);
  if (firstShopCategory) {
    _.map(firstShopCategory.items, item => {
      if (item) {
        const { price } = item;
        if (!minimumPrice || price < minimumPrice) {
          minimumPrice = price;
        }
      }
    });
  }
  return minimumPrice;
};

export const displayPriceFormat = (price, innerHtml, decimal = 2) => {
  price = `${String(Number(price).toFixed(decimal)).replace('.', ',')} €`;
  if (innerHtml) {
    return price;
  }
  return <span className='no-localization'>{price}</span>;
};

export const getOptionAssociatedProduct = option => {
  let product = null;
  const { sections } = store.getState().shopReducer;
  const { productKey } = option;
  if (productKey) {
    _.map(sections, section => {
      if (section) {
        const productSection = _.find(section.products, product => {
          return product.key === productKey;
        });
        if (productSection) {
          product = productSection;
        }
      }
    });
  }
  return product;
};

export const isProductDisabledOrderType = product => {
  const { address: customerAddress, orderType } = store.getState().pendingOrderReducer;
  let isProductDisabledOrderType = false;
  if (customerAddress) {
    switch (orderType) {
      case ORDER_TYPE_CLICK_AND_SEAT:
        isProductDisabledOrderType = product.disabledCS
        break;
      case ORDER_TYPE_CLICK_AND_COLLECT:
        isProductDisabledOrderType = product.disabledCC
        break;
      case ORDER_TYPE_DELIVERY:
        isProductDisabledOrderType = product.disabledDelivery
        break;
    }
  }
  return isProductDisabledOrderType;
};

export const isProductDisabled = product => {
  return isProductDisabledOrderType(product) || product.disabled || product.stock <= 0;
};


export const getNextDay = (daysOfWeek, startDate, endDate, date) => {
  const currentDate = date || moment(startDate)
  const isNextDateInRange = (date) => date.isBetween(startDate, endDate, null, '[]');

  for (let i = 1; i <= 7; i++) {
    const nextDate = currentDate.clone().add(i, 'days');
    const nextDayKey = nextDate.format('dddd');

    if (daysOfWeek[nextDayKey] && isNextDateInRange(nextDate)) {
      nextDate.locale('fr');
      return `${nextDate.format('dddd')} ${nextDate.format('DD MMMM')}`;
    }
  }

  return null;
};

export const isSectionNotInRange = (section, checkEndDate = false) => {
  const { activationRange } = section || {};
  if (!activationRange || !activationRange.withDateRange) {
    return false;
  }

  const { startDate, endDate, daysOfWeek } = activationRange;
  const currentDate = moment();
  const currentDayOfWeek = currentDate.format('dddd');
  const currentDayConfig = daysOfWeek?.[currentDayOfWeek];

  if (!startDate || !endDate || !daysOfWeek) {
    return true;
  }

 if (checkEndDate) {
    let isVisible = false;
    const oneWeekBeforeStartDate = moment(startDate).subtract(7, 'days');
    if (currentDayConfig?.fullDay || (currentDayConfig?.slot && currentDate.isSameOrBefore(moment(currentDayConfig.slot.split('-')[1], 'HH:mm')))) {
      isVisible = true;
    } else {
      isVisible = getNextDay(daysOfWeek, startDate, endDate, currentDate) || currentDate.isBefore(startDate)
    }
    return !(isVisible && currentDate.isSameOrAfter(oneWeekBeforeStartDate) && currentDate.isBefore(endDate));
}

  if (!currentDayConfig) {
    return true;
  }

  const { slot = '', fullDay } = currentDayConfig;
  const [startTime, endTime] = _.map(slot?.split('-'), time => moment(time, 'HH:mm'));

  const isWithinTimeRange = currentDate.isBetween(startTime, endTime, null, '[]');
  return !(currentDate.isBetween(startDate, endDate, null, '[]') && (isWithinTimeRange || fullDay));
};

const isSectionDisabled = section => {
  const hasProductNotDisabled = _.find(section.products, product => {
    return !isProductDisabledOrderType(product) && !product?.hidden;
  });
  return (section.products && !hasProductNotDisabled) || section.hidden || isSectionNotInRange(section, true);
};

export const filterSections = sections => {
  return sections?.length
  ? sections?.filter(section => section && !isSectionDisabled(section))
  : [];
};

export const getNbInCartOfProduct = (key, productKey) => {
  const { pendingOrderReducer } = store.getState();
  let nbInCart = 0;
  _.map(pendingOrderReducer?.commande, productInCart => {
    const { nb } = productInCart;
    if (key === productInCart.key || (productKey && productKey === productInCart.key)) {
      nbInCart += nb;
    } else {
      _.map(productInCart.items, itemInCart => {
        if (itemInCart) {
          _.map(itemInCart.options, optionInCart => {
            // productKey can be an option key or a product key
            if (key === optionInCart.key || key === optionInCart.productKey) {
              const optionProductNb = optionInCart.productNb || 1;
              const optionProduct = getOptionAssociatedProduct(optionInCart);
              if (optionProduct) {
                nbInCart += nb * optionProductNb;
              }
            }
          });
        }
      });
    }
  });
  return nbInCart;
};

export const getStockRemaining = (productStock, nbInCart, productNb) => {
  let stockRemainingOption = null;
  if (parseInt(productStock, 10) >= 0) {
    stockRemainingOption = parseFloat(productStock - nbInCart - productNb, 10);
  }
  return stockRemainingOption;
};

export const getCurrentNbOption = (productItems, optionKey) => {
  let nbOption = 0;
  _.map(productItems, category => {
    if (category && category.options) {
      _.map(category.options, opt => {
        if (opt.key === optionKey) {
          nbOption++;
        }
      });
    }
  });
  return nbOption;
};

export const generateCounterOptionKey = (categoryKey, optionKey) => `${categoryKey}${optionKey}`;

export const getManagementFee = (totalProductPrice) => {
  const { managementFeeObj } = store.getState().configurationReducer;
   const { orderType } = store.getState().pendingOrderReducer;

  if (!isObjectNotNull(managementFeeObj) || !managementFeeObj.enabled || !orderType) {
    return null;
  }

  const managementFee = managementFeeObj[orderType];
  if (!managementFee) {
    return null;
  }

  const { fee, maxFee, minFee } = managementFee;
  const managementFeeValue = Math.min(Math.max((fee / 100) * totalProductPrice, minFee), maxFee)
  return Math.round(managementFeeValue * 100) / 100;
};
