import apiService from '@dishopsaas/dishop-backend-api-service'
import { isTerminalVertical } from '../../utils/config'
import { sendCloudWatchLogs } from '../../utils/logs'
import { updateAddressAndShop } from '../../utils/customer-address-and-shops'
import store from '../../redux/store'
import { resetCommande, showMessageModal, updateCommande } from '../../redux/actions'
import _ from 'lodash'
import { isProductDisabled, isSectionNotInRange } from '../../utils'
import { ORDER_TYPE_DELIVERY } from '../../constants'

export const getClasses = () => {
  const { isHorizontalSection } = store.getState().componentReducer
  let listSectionClass = 'col-lg-2 col-4 col-md-3 mb-3 pr-4'
  let listProductClass = 'col-lg-10 col-8 col-md-9'
  if (isHorizontalSection) {
    listProductClass = 'col-12 p-0'
  }
  if (isTerminalVertical()) {
    listSectionClass = 'col-3 mb-3 pr-4'
    listProductClass = 'col-9'
  }
  return { listSectionClass, listProductClass }
}

export const displayOrNotHeader = displayNavBar => {
  const header = document.getElementsByTagName('header')[0]
  if (header) {
    if (displayNavBar) {
      header.style.visibility = 'hidden'
    } else {
      header.style.visibility = 'visible'
    }
  }
}

export const resetCommandeIfNeeded = async () => {
  const { userConnected, isUserAnonymous, user } = store.getState().userReducer
  if (userConnected || isUserAnonymous) {
    const { customerId } = user
    sendCloudWatchLogs(`Resetting commande due to changing shop for customer ${customerId}`)
    await apiService.pendingOrdersDelete([customerId, 'commande'])
  } else {
    store.dispatch(resetCommande())
  }
}

export const updateShopFromLink = async (component, customerAddress, orderType) => {
  const { marketplace, updateShopIsLoaded, match, history } = component.props
  if (!marketplace?.enabled) {
    return
  }
  const shopIdFromLink = match.params.shopId
  if (customerAddress && shopIdFromLink) {
    updateShopIsLoaded(false)
    await updateAddressAndShop(shopIdFromLink, customerAddress, orderType)
  } else if (!customerAddress) {
    history.push('/')
  }
}

export const removeItemFromCommande = async (nextProps, component) => {
  const {
    isUserAnonymous,
    userConnected,
    customerId,
    shopIsLoaded,
    orderType: prevOrderType
  } = component.props
  const { commande, sections, networkFees = {}, shopId, orderType = {} } = nextProps
  const orderTypeCheck =
    !_.isEqual(orderType, prevOrderType) &&
    (_.isEqual(orderType, ORDER_TYPE_DELIVERY) || _.isEqual(prevOrderType, ORDER_TYPE_DELIVERY))
  const newOrder = _.cloneDeep(commande)
  let isShopChanged = false
  _.map(commande, async (commandeProduct, key) => {
    isShopChanged = commandeProduct.shopId !== shopId
    if (shopIsLoaded && (isShopChanged || (networkFees?.delivery?.productFees && orderTypeCheck))) {
      await resetCommandeIfNeeded()
      return
    }
    const section = sections?.find((existingSection) => {
      return (existingSection.key === commandeProduct.sectionKey) && existingSection?.products[commandeProduct.key];
    })
    const productToRemove = _.find(section?.products, (product) => {
      return (
        product.key === commandeProduct.key &&
        (isProductDisabled(product) ||
          parseFloat(product.price) !== commandeProduct.unitPrice || section.hidden)
      )
    })
   if (!section || productToRemove || isSectionNotInRange(section)) delete newOrder[key]
    if (!_.isEqual(newOrder, commande)) {
      if ((userConnected || isUserAnonymous)) {
        if (_.isEmpty(newOrder)) {
          await apiService.pendingOrdersDelete([customerId, 'commande'])
        } else {
          await apiService.pendingOrdersUpsert([customerId, 'commande'], newOrder, { updateMode: 'replace' })
        }
      } else {
        store.dispatch(_.isEmpty(newOrder) ? resetCommande() : updateCommande(newOrder))
      }
      if (!isShopChanged) {
        store.dispatch(
          showMessageModal(
            `Il existe un ou plusieurs articles dans votre panier qui ne sont plus disponibles.`
          )
        )
      }
    }
  })
}
