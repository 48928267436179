import { sendPostRequest } from '.';
import { API_CREATE_CUSTOMER_HUBRISE, API_UPDATE_CUSTOMER_HUBRISE, HUBRISE } from '../constants';
import store from '../redux/store';
import { getIdToken } from './firebase/firebaseAuthentication'

// Function to check if HUBRISE integration is enabled and fetch idToken
const checkHubriseIntegrationAndFetchToken = async () => {
  const { shopConfiguration } = store.getState().shopReducer;
  if (
    shopConfiguration.externalIntegration?.type === HUBRISE &&
    shopConfiguration.externalIntegration?.enabled
  ) {
    return await getIdToken();
  }
  return null;
};

export const signUpCustomerHubrise = async (customerId, customerData) => {
  const idToken = await checkHubriseIntegrationAndFetchToken();
  if (!idToken) return;

  const { firstName, lastName, email, numero, newsletter } = customerData;
  const customer = {
    first_name: firstName,
    last_name: lastName,
    email,
    phone: numero,
    sms_marketing: !!newsletter,
    email_marketing: !!newsletter
  };

   if (numero) {
      customer.phone = numero;
    }

  const { shopId } = store.getState().shopReducer;

  const headers = { Authorization: `Bearer ${idToken}` };
  const response = await sendPostRequest(
    API_CREATE_CUSTOMER_HUBRISE,
    { customer, shopId, customerId },
    headers
  );
  return response.data;
};

export const updateCustomerHubrise = async customerObject => {
  const idToken = await checkHubriseIntegrationAndFetchToken();
  if (!idToken) return;

  const { user } = store.getState().userReducer;
     const { hubriseId, email } = user;
  const { shopId } = store.getState().shopReducer;

  let customer = {};
  if (customerObject && hubriseId) {
    const { address, firstName, lastName, numero } = customerObject;
    if (address) {
      const { city, street, location, postalCode, instruction = '', street2 = '', streetNumber } = address;
      customer = {
        address_1: `${streetNumber ? `${streetNumber} ` : ''}${street}`,
        address_2: street2,
        delivery_notes: instruction,
        city,
        postal_code: String(postalCode),
        latitude: location.latitude.toString(),
        longitude: location.longitude.toString(),
         phone: numero
      };
    } else {
      customer = { first_name: firstName, last_name: lastName, phone: numero };
    }
    customer.email = email;

    const headers = { Authorization: `Bearer ${idToken}` };
    const response = await sendPostRequest(
      API_UPDATE_CUSTOMER_HUBRISE,
      { customer, hubriseId, shopId },
      headers
    );
    return response;
  }
};